/* eslint-disable max-lines */
import * as Sentry from '@sentry/nextjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Banner, Box, Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { FiledByType, OrderLookupType } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import { mobileTextFieldClassName } from '@/components/Form/index.css';
import {
  ClickWrapWithPreviewSection,
  EmailTextField,
  MainGiftReturnButton,
  OrderLookupFormTitleText,
  OrderLookupSubmitButton,
  OrderNumberTextField,
  PolicyWithPreviewSection,
} from '@/features/preview/components/WithPreviewSection';
import { useClickwrapContext } from '@/features/returns/components/ClickwrapProvider';
import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import { useUniversalRouting } from '@/features/returns/hooks/useUniversalRouting';
import queryParamsKeys from '@/features/returns/types/QueryParamsKeys';
import { IExternalConfig, IQueryParamOrderInfo } from '@/features/returns/types/externalConfig';
import decodeBase64JsonString from '@/features/returns/utils/decodeBase64JsonString';
import useDevice from '@/hooks/useDevice';

import MainPageLayout from './MainPageLayout';
import PoweredBy from './PoweredBy';

import { useFindOrder } from '../hooks/useFindOrder';
import { useOrderLookupSetting } from '../hooks/useOrderLookupSetting';

const robotError = 'validation.error.robot';
const matchingError = 'validation.error.wrongOrderNoEmail';
const traceId = uuidv4();
export default function MainPage() {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    policy_url: policyUrl,
    external_return_policy_page: externalReturnPolicyPage,
    show_returns_page_powered_by: showPoweredBy,
    organization: { id: orgId },
  } = useShopInfo();

  const [isFetchingGoogleToken, setIsFetchingGoogleToken] = useState(false);

  const { dispatch, children, context } = useFlow();

  const { enablePostalCode, enableEmail, onlyEmail } = useOrderLookupSetting();

  const {
    clickwrapConfig,
    isClickwrapChecked,
    warningMsgVisible,
    setWarningMsgVisible,
    shouldCheckClickwrap,
    setShouldIgnoreClickwrap,
  } = useClickwrapContext();

  const { isLoading: isGettingOrder, findOrder } = useFindOrder({
    onError: () => {
      setErrorKey(matchingError);
    },
    onInputError: (error) => {
      if (error.email) {
        setErrorKey(matchingError);
      }
    },
  });

  const orderLookupSubFlow = children?.orderLookupSubFlow;

  useEffect(() => {
    if (orgId) {
      Sentry.setTag('orgId', orgId);
    }

    if (orderLookupSubFlow?.snapshot?.status === 'done') {
      const email = orderLookupSubFlow?.snapshot?.output?.email;
      const orderNumber = orderLookupSubFlow?.snapshot?.output?.orderNumber;
      if (email) {
        Sentry.setUser({ email });
        Sentry.setTag('email', email);
      }
      if (orderNumber) {
        Sentry.setTag('orderNumber', orderNumber);
      }
    }
  }, [
    orgId,
    orderLookupSubFlow?.snapshot?.status,
    orderLookupSubFlow?.snapshot?.output?.email,
    orderLookupSubFlow?.snapshot?.output?.orderNumber,
  ]);

  const isLoading = isGettingOrder || isFetchingGoogleToken;

  const [errorKey, setErrorKey] = useState('');

  const { getSearchParam } = useUniversalRouting();

  const qsOrderValue = useMemo<IQueryParamOrderInfo>(() => {
    const defaultValue: IQueryParamOrderInfo = {
      order_number: '',
      email: '',
      type: '',
      filed_by: '',
      intention_id: '',
    };

    try {
      const qs = getSearchParam<string | undefined>(queryParamsKeys.ORDER_QS);

      if (qs) {
        const result = decodeBase64JsonString<IQueryParamOrderInfo>(qs);
        return result;
      }
    } catch (e) {
      return defaultValue;
    }

    return defaultValue;
  }, [getSearchParam]);

  const externalConfig = useMemo<IExternalConfig | null>(() => {
    const externalConfigStr = getSearchParam<string | undefined>(queryParamsKeys.EXTERNAL_CONFIG);
    if (externalConfigStr) {
      const externalConfig = decodeBase64JsonString<IExternalConfig>(externalConfigStr);

      return externalConfig;
    }
    return null;
  }, [getSearchParam]);

  const [orderNumber, setOrderNumber] = useState(qsOrderValue.order_number || '');

  const [emailOrZipCode, setEmailOrZipCode] = useState(qsOrderValue.email || '');

  const handleSubmit = useCallback(
    async ({ orderNumber, emailOrZipCode }: { orderNumber: string; emailOrZipCode: string }) => {
      if (!orderLookupSubFlow) return;

      const isEmail = emailOrZipCode.includes('@');

      if (shouldCheckClickwrap) {
        setWarningMsgVisible(true);
        return;
      }

      if ((isEmail && !enableEmail) || (!isEmail && !enablePostalCode)) {
        setErrorKey(matchingError);
        return;
      }

      const commonPayload = {
        isMerchantMode: !!externalConfig?.merchantModeConfig,
        ...(qsOrderValue.intention_id && { intentionId: qsOrderValue.intention_id }),
        ...(qsOrderValue.filed_by && { filedBy: qsOrderValue.filed_by as FiledByType }),
        merchantModeToken: externalConfig?.merchantModeConfig?.session.token,
        resolutionWhiteList: externalConfig?.activedResolutionOptions,
        rmaId: qsOrderValue.rma_id,
        traceId: externalConfig?.merchantModeConfig?.trace_id || traceId,
      };

      if (isEmail) {
        findOrder({
          orderLookupType: OrderLookupType.EMAIL,
          email: emailOrZipCode.trim(),
          orderNumber: orderNumber.trim(),
          ...commonPayload,
        });
      } else {
        if (executeRecaptcha) {
          setIsFetchingGoogleToken(true);
          executeRecaptcha()
            .then((gReCaptchaToken) => {
              setIsFetchingGoogleToken(false);
              findOrder({
                orderLookupType: OrderLookupType.ZIPCODE,
                zipCode: emailOrZipCode.trim(),
                orderNumber: orderNumber.trim(),
                googleToken: gReCaptchaToken,
                ...commonPayload,
              });
            })
            .catch(() => {
              setErrorKey(robotError);
            });
        }
      }
    },
    [
      executeRecaptcha,
      externalConfig?.merchantModeConfig,
      externalConfig?.activedResolutionOptions,
      qsOrderValue.intention_id,
      qsOrderValue.filed_by,
      qsOrderValue.rma_id,
      orderLookupSubFlow,
      enableEmail,
      enablePostalCode,
      findOrder,
      setWarningMsgVisible,
      shouldCheckClickwrap,
    ],
  );

  const autoSubmitByQs = useCallback(async () => {
    const { order_number, email, type } = qsOrderValue;

    if (order_number && email) {
      // when from admin onBoarding, not auto submit
      if (type === 'onBoarding') return;
      // getTrackerInstance().reportClickEvent(
      //   BizClickName.return_query_query_string
      // )

      // ignore the clickwrap for auto submit from admin
      setShouldIgnoreClickwrap(true);
      handleSubmit({
        orderNumber: order_number,
        emailOrZipCode: email,
      });
    }
  }, [qsOrderValue, handleSubmit, setShouldIgnoreClickwrap]);

  useEffect(() => {
    // Reset order state when user navigates back to the main screen

    if (onlyEmail || executeRecaptcha) {
      autoSubmitByQs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha, onlyEmail]);
  return (
    <MainPageLayout>
      <Flex flex={1} direction='column'>
        <Flex direction='column' alignItems='center' gap={SpacingVars['2']}>
          <Flex direction='column' alignSelf='stretch'>
            <Flex direction='column' gap={SpacingVars['6']}>
              <OrderLookupFormTitleText variant={isMobile ? 'title1' : 'title2'} textAlign='center'>
                {t('page.landing.title')}
              </OrderLookupFormTitleText>
              <Flex
                direction='column'
                gap={SpacingVars['4']}
                paddingBottom={isMobile ? SpacingVars['8'] : SpacingVars['4']}
              >
                {errorKey && (
                  <Banner
                    info={<Text wordBreak='break-word'>{t(errorKey)}</Text>}
                    variant='warning'
                  />
                )}
                <Flex direction='column' gap={SpacingVars['2']}>
                  {/* FIXME: 移动端应该是组件库去处理样式 */}
                  <OrderNumberTextField
                    fullWidth
                    placeholder={t('page.landing.orderNumber')}
                    value={orderNumber}
                    onChange={setOrderNumber}
                    autoComplete='off'
                    clearable
                    className={isMobile ? mobileTextFieldClassName : undefined}
                    aria-label={t('page.landing.orderNumber')}
                  />
                  <EmailTextField
                    fullWidth
                    placeholder={t('page.landing.email')}
                    value={emailOrZipCode}
                    onChange={setEmailOrZipCode}
                    autoComplete='off'
                    clearable
                    className={isMobile ? mobileTextFieldClassName : undefined}
                    aria-label={t('page.landing.email')}
                  />
                </Flex>
              </Flex>
            </Flex>
            <OrderLookupSubmitButton
              isLoading={isLoading}
              isDisabled={!orderNumber || !emailOrZipCode}
              size='large'
              onPress={() => {
                handleSubmit({
                  orderNumber,
                  emailOrZipCode,
                });
              }}
            >
              {t('page.landing.findYourOrder')}
            </OrderLookupSubmitButton>
          </Flex>

          <Flex direction='column' gap={SpacingVars['6']}>
            <Flex justifyContent='center'>
              {context?.storeConfig?.giftReturnSetting?.giftReturnAvailability && (
                <MainGiftReturnButton
                  variant='link'
                  onPress={() => {
                    dispatch?.({ type: 'GO_TO_GIFT_RETURN' });
                  }}
                >
                  <Text variant='subtitle1'>{t('page.landing.action.giftReturn')}</Text>
                </MainGiftReturnButton>
              )}
            </Flex>
            <Flex direction='column' gap={SpacingVars['1']}>
              {clickwrapConfig?.feature_applied && (
                <ClickWrapWithPreviewSection
                  isClickwrapChecked={isClickwrapChecked}
                  clickwrapError={warningMsgVisible}
                />
              )}
              <PolicyWithPreviewSection
                policyTextHeight={90}
                {...{ externalReturnPolicyPage, policyUrl }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box flex={1} />
        {showPoweredBy && (
          <Box paddingTop={SpacingVars['6']}>
            <PoweredBy isPreview={false} />
          </Box>
        )}
      </Flex>
    </MainPageLayout>
  );
}
