import { GetServerSideProps } from 'next';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import OrderList from '@/features/app-proxy-order-list/OrderList';
import MainPage from '@/features/order-lookup/components/MainPage';
import { ConnectorOrders, getConnectOrders } from '@/features/returns/resources/getConnectOrders';
import getStores from '@/features/returns/resources/getStores';
import { IQueryParamOrderInfo } from '@/features/returns/types/externalConfig';
import { getShopifyAppKey, queryToString } from '@/features/returns/utils/common';
import decodeBase64JsonString from '@/features/returns/utils/decodeBase64JsonString';
import { useIsReCaptchaReady } from '@/hooks/useIsReCaptchaReady';

import { Order } from '../features/app-proxy-order-list/types';

const transformOrders = (orders?: ConnectorOrders['orders'] | null): Order[] =>
  orders?.map((order) => ({
    email: order.customer?.emails?.[0] ?? '',
    order_number: order.order_number,
    // metrics 可能为空
    created_at: order.metrics?.placed_at || '',
    products: order.items.map((item) => ({
      image_url: item.image_urls?.[0] ?? '',
      name: item.title,
      number: item.quantity,
      price: item.discounted_price_incl_tax_set?.presentment_money?.amount ?? 0,
      currency: item.discounted_price_incl_tax_set?.presentment_money?.currency ?? 'USD',
      variants: item.variant_title?.split('/') ?? [],
    })),
  })) ?? [];

// // 解析 qs 参数，如果有 order number 和 email，跳转到查询页面而不是 order list 页面
const useGetIsRedirectToMainPage = () => {
  const params = useSearchParams();
  const qs = params ? params.get('qs') : null;

  if (!qs) {
    return false;
  }

  const { order_number, email } = decodeBase64JsonString<IQueryParamOrderInfo>(qs) || {};

  return order_number && email;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  // If you request this page with the preview mode cookies set:
  //
  // - context.preview will be true
  // - context.previewData will be the same as
  //   the argument used for `setPreviewData`.
  const { preview, previewData } = context;

  const { isAppProxy } = await import('../../web/features/returns/utils/common');

  const isProxy = isAppProxy(context.res);

  const defaultProps: {
    isAppProxy: boolean;
    orders?: Order[];
  } = {
    isAppProxy: isProxy,
  };

  const { shop, logged_in_customer_id } = context.query;

  if (isProxy && logged_in_customer_id) {
    const appKey = getShopifyAppKey(String(shop));

    const res = await getStores(appKey);

    const organizationId = res.connections[0]?.organization.id;

    const { orders } = await getConnectOrders({
      customerId: queryToString(logged_in_customer_id),
      organizationId,
      appKey,
    });
    defaultProps.orders = transformOrders(
      orders?.filter((order) => order.customer.external_id === logged_in_customer_id) ?? [],
    );
  }

  if (preview) {
    return {
      props: {
        ...defaultProps,
        preview,
        previewData,
      },
    };
  }
  return {
    props: defaultProps, // will be passed to the page component as props
  };
};

interface IHomeProps {
  isAppProxy: boolean;
  orders?: Order[];
}

const toggleCaptchaBadge = (show: boolean) => {
  const badges = document.getElementsByClassName('grecaptcha-badge');
  Array.from(badges).forEach((badge) => {
    if (badge instanceof HTMLElement) {
      badge.style.visibility = show ? 'visible' : 'hidden';
    }
  });
};

export default function Home({ isAppProxy, orders = [] }: IHomeProps) {
  const isRedirectToMainPage = useGetIsRedirectToMainPage();
  const isReCaptchaReady = useIsReCaptchaReady();

  useEffect(() => {
    if (isReCaptchaReady) {
      toggleCaptchaBadge(true);
    }
    return () => toggleCaptchaBadge(false);
  }, [isReCaptchaReady]);

  if (!isAppProxy) {
    return <MainPage />;
  }

  const isRedirectToOrderListInAppProxy = orders && orders.length > 0 && !isRedirectToMainPage;

  return (
    <>
      {/* 恢复此页面下 shopify 的 footer 标签 */}
      <style
        dangerouslySetInnerHTML={{
          __html: `footer.footer {display: block;}`,
        }}
      />
      {isRedirectToOrderListInAppProxy ? <OrderList orders={orders} /> : <MainPage />}
    </>
  );
}
