import { useFlow } from 'returns-logics/react';

export const useOrderLookupSetting = () => {
  const { context } = useFlow();

  const enablePostalCode = context?.storeConfig?.shopInfo?.order_lookup?.by_postal_code_enabled;
  const enableEmail = context?.storeConfig?.shopInfo?.order_lookup?.by_email_enabled;
  const onlyEmail = enableEmail && !enablePostalCode;

  return {
    enablePostalCode,
    enableEmail,
    onlyEmail,
  };
};
