import { useEffect } from 'react';

import { OrderLookupInputError, RequestErrorType, SubmitPayload } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

interface Props {
  onError?: (error: RequestErrorType) => void;
  onSuccess?: VoidFunction;
  onInputError?: (error: OrderLookupInputError) => void;
}

export const useFindOrder = ({ onError, onSuccess, onInputError }: Props) => {
  const { children } = useFlow();

  const orderLookupSubFlow = children?.orderLookupSubFlow;

  useEffect(() => {
    if (!orderLookupSubFlow?.on || !onError) return;

    const { unsubscribe } = orderLookupSubFlow.on('error', ({ data }) => {
      onError(data.error);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLookupSubFlow]);

  useEffect(() => {
    if (!orderLookupSubFlow?.on || !onInputError) return;

    const { unsubscribe } = orderLookupSubFlow.on('inputError', ({ data }) => {
      onInputError(data.error);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLookupSubFlow]);

  useEffect(() => {
    if (!orderLookupSubFlow?.on || !onSuccess) return;

    const { unsubscribe } = orderLookupSubFlow.on('success', () => {
      onSuccess();
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLookupSubFlow]);

  return {
    findOrder: (data: SubmitPayload) => {
      orderLookupSubFlow?.dispatch({ type: 'SUBMIT', data });
    },
    isLoading: orderLookupSubFlow?.currentStep.isLoading,
  };
};
