'use client';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';

import OrderItem from './components/OrderItem';
import PagingStepper from './components/PagingStepper';
import Policy from './components/Policy';
import { orderListContainer } from './style.css';
import { Order } from './types';

import { useReturns } from '../returns/hooks/useReturns';

export function convertShopifyShopLangCode(code: string, i18nLangCodes: string[]) {
  if (i18nLangCodes.includes(code)) return code;
  const language = i18nLangCodes.find((lang) => {
    return lang.slice(0, 2) === code.slice(0, 2);
  });
  if (language?.includes('zh')) return 'zh-Hant';
  if (language?.includes('pt')) return 'pt-PT';
  return language;
}

const OrderList = (props: { orders: Order[] }) => {
  const { orders } = props;
  const { t, i18n } = useTranslation();
  const {
    policy_url: policyUrl,
    policy_text: policyText,
    external_return_policy_page: externalReturnPolicyPage,
    languages,
  } = useReturns()?.shopInfo ?? {};

  const minHeight = useStepCardMinHeight();

  const isMobile = useDevice().mobile;

  const pageSize = 10;
  const pageNumber = Math.ceil(orders.length / pageSize);
  const [pageIndex, setPageIndex] = useState(0);

  const pageOrders = orders.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  // 上一页
  const onPrevious = () => {
    setPageIndex(pageIndex - 1);
  };

  // 下一页
  const onNext = () => {
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    const langCode = convertShopifyShopLangCode(window.customerLangCode, languages ?? []);
    i18n.changeLanguage(langCode);
  }, [i18n, languages]);

  const content = (
    <Flex direction='column' flex={1}>
      <Text
        variant='title1'
        color={ColorVars['Grey']['Grey1200']}
        textAlign='center'
        paddingBottom={SpacingVars['6']}
      >
        {t('page.landing.title')}
      </Text>
      <Policy {...{ policyUrl, policyText, externalReturnPolicyPage }} />
      <Flex direction='column' gap={SpacingVars['2']} paddingTop={SpacingVars['10']} flex={1}>
        <Text variant='title3' color={ColorVars['Grey']['Grey1200']}>
          {t('page.description.yourOrders')}
        </Text>
        <Flex direction='column' gap={SpacingVars['4']}>
          {pageOrders.map((order) => (
            <OrderItem key={order.order_number} {...{ order }} />
          ))}
        </Flex>
      </Flex>
      {orders.length > pageSize && (
        <PagingStepper
          isPreviousDisabled={pageIndex <= 0}
          isNextDisabled={pageIndex >= pageNumber - 1}
          {...{ onPrevious, onNext }}
        />
      )}
    </Flex>
  );

  if (isMobile) {
    return (
      <Box flex={1} backgroundColor={ColorVars['B&W'].Background} padding={SpacingVars['4']}>
        {content}
      </Box>
    );
  }

  return (
    <Card
      width={800}
      minHeight={minHeight}
      paddingX={SpacingVars['10']}
      paddingY={SpacingVars['8']}
      className={orderListContainer}
    >
      {content}
    </Card>
  );
};

export default OrderList;
